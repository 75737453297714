import { useState } from "react";
import Transition from "./Transition";
import OutsideClickHandler from "react-outside-click-handler";
import Link from "next/link";
import VirtualSnipe from "./VirtualSnipe";
import classNames from "classnames";
import Canvas from "./Canvas";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Logo from "../public/images/logos/logo.png";

export default function Nav({ translucent, isHome, isCaseStudy }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [solutionsOpen, setSolutionsOpen] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const { width } = useWindowDimensions();

  return (
    <div
      className={classNames(
        "",
        translucent && "bg-opacity-75 shadow-md bg-white",
        isCaseStudy && "bg-transparent relative z-20"
      )}
    >
      {isHome && (
        <div className="relative film">
          <Canvas width={width} />
        </div>
      )}
      {/* <VirtualSnipe open={router.pathname === "/"} /> */}
      <div className="px-4 mx-auto max-w-7xl sm:px-6">
        <div
          className={classNames(
            "flex justify-between items-center py-6 lg:justify-start lg:space-x-10",
            translucent ? "" : "border-b-2 border-gray-100"
          )}
        >
          <div className="lg:flex-1">
            <Link href="/">
              <a className="flex">
                <img
                  className="w-auto h-6 max-w-none"
                  src={
                    isHome || isCaseStudy
                      ? Logo.src
                      : "https://eventive-co.imgix.net/images/eventive-logo.png?h=200"
                  }
                  alt="Eventive"
                />
              </a>
            </Link>
          </div>
          <div className="-my-2 -mr-2 lg:hidden">
            <button
              type="button"
              className={`inline-flex items-center justify-center p-2 rounded-md ${translucent
                ? "text-gray-700 hover:text-gray-900"
                : isHome || isCaseStudy
                  ? "text-white "
                  : "text-gray-400"
                } hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out`}
              onClick={() => setMobileOpen(!mobileOpen)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <nav className="hidden space-x-10 lg:flex ">
            <div className="relative">
              <button
                data-solutions-trigger={true}
                type="button"
                className={`${isHome || isCaseStudy
                  ? "text-white"
                  : solutionsOpen
                    ? "text-gray-900"
                    : translucent
                      ? "text-gray-700 hover:text-gray-900"
                      : "text-gray-500 hover:text-gray-900"
                  } group inline-flex items-center space-x-2 text-base leading-6 font-medium focus:outline-none transition ease-in-out`}
                onClick={(e) => {
                  setSolutionsOpen(!solutionsOpen);
                }}
              >
                <span>Offerings</span>
                <svg
                  className={`${isHome || isCaseStudy
                    ? "text-white"
                    : solutionsOpen
                      ? "text-gray-900 "
                      : translucent
                        ? "text-gray-700 hover:text-gray-900"
                        : "text-gray-500 hover:text-gray-900"
                    } h-5 w-5 transition ease-in-out duration-150`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <Transition
                show={solutionsOpen}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <div
                  className="absolute z-50 w-screen px-2 mt-3 -ml-4 transform sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  style={{ maxWidth: "15rem" }}
                >
                  <OutsideClickHandler
                    disabled={!solutionsOpen}
                    useCapture={false}
                    onOutsideClick={(e) => {
                      const trigger = document.querySelector(
                        "[data-solutions-trigger]"
                      );
                      if (trigger !== e.target && !trigger.contains(e.target)) {
                        setSolutionsOpen(false);
                      }
                    }}
                  >
                    <div className="rounded-lg shadow-lg">
                      <div className="overflow-hidden rounded-lg shadow-xs">
                        <div className="relative z-20 grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                          {/* <Link href="/virtual">
                            <a className="flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50">
                              <svg
                                className="flex-shrink-0 w-6 h-6 text-red-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                />
                              </svg>
                              <div className="space-y-1">
                                <p className="text-base font-medium leading-6 text-gray-900">
                                  Virtual Platform
                                </p>
                                <p className="text-sm leading-5 text-gray-500">
                                  {copy.virtual_description}
                                </p>
                              </div>
                            </a>
                          </Link> */}

                          <Link href="/film-festivals">
                            <a className="flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50">
                              <svg
                                className="flex-shrink-0 hidden w-6 h-6 text-red-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                                />
                              </svg>
                              <div className="space-y-1">
                                <p className="text-base font-medium leading-6 text-gray-900">
                                  Film Festivals
                                </p>
                                {/* <p className="text-sm leading-5 text-gray-500">
                                  {copy.film_festival_description}
                                </p> */}
                              </div>
                            </a>
                          </Link>

                          <Link href="/filmmakers">
                            <a className="flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50">
                              <svg
                                className="flex-shrink-0 hidden w-6 h-6 text-red-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                />
                              </svg>
                              <div className="space-y-1">
                                <p className="text-base font-medium leading-6 text-gray-900">
                                  Filmmakers
                                </p>
                                {/* <p className="text-sm leading-5 text-gray-500">
                                  {copy.distributors_description}
                                </p> */}
                              </div>
                            </a>
                          </Link>

                          <Link href="/cinema">
                            <a className="flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50">
                              <svg
                                className="flex-shrink-0 hidden w-6 h-6 text-red-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                                />
                              </svg>
                              <div className="space-y-1">
                                <p className="text-base font-medium leading-6 text-gray-900">
                                  Arthouse Cinema
                                </p>
                                {/* <p className="text-sm leading-5 text-gray-500">
                                  {copy.cinema_description}
                                </p> */}
                              </div>
                            </a>
                          </Link>
                          <Link href="/virtual">
                            <a className="flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50">
                              <svg
                                className="flex-shrink-0 hidden w-6 h-6 text-red-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                                />
                              </svg>
                              <div className="space-y-1">
                                <p className="text-base font-medium leading-6 text-gray-900">
                                  Eventive Virtual
                                </p>
                                {/* <p className="text-sm leading-5 text-gray-500">
                                  {copy.cinema_description}
                                </p> */}
                              </div>
                            </a>
                          </Link>

                          {/* <Link href="/filmmakers">
                            <a className="flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50">
                              <svg
                                className="flex-shrink-0 w-6 h-6 text-red-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                />
                              </svg>
                              <div className="space-y-1">
                                <p className="text-base font-medium leading-6 text-gray-900">
                                  Filmmakers
                                </p>
                                <p className="text-sm leading-5 text-gray-500">
                                  Anim aute id magna aliqua ad ad non deserunt
                                  sunt. Qui irure qui lorem cupidatat commodo.
                                </p>
                              </div>
                            </a>
                          </Link> */}
                        </div>
                        <div className="px-5 py-5 space-y-6 bg-gray-50 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {/* <div className="flow-root">
                            <a
                              href="#"
                              className="flex items-center p-3 -m-3 space-x-3 text-base font-medium leading-6 text-gray-900 transition duration-150 ease-in-out rounded-md hover:bg-gray-100"
                            >
                              <svg
                                className="flex-shrink-0 w-6 h-6 text-gray-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                              <span>Watch Demo</span>
                            </a>
                          </div> */}
                          <div className="flow-root">
                            <Link href="/start?goal=learn_more">
                              <a className="flex items-center p-3 -m-3 space-x-3 text-base font-medium leading-6 text-gray-900 transition duration-150 ease-in-out rounded-md hover:bg-gray-100">
                                <svg
                                  className="flex-shrink-0 w-6 h-6 text-gray-400"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                  />
                                </svg>
                                <span>Contact Sales</span>
                              </a>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OutsideClickHandler>
                </div>
              </Transition>
            </div>

            <div className="relative">
              <button
                data-about-trigger={true}
                type="button"
                className={`${isHome || isCaseStudy
                  ? "text-white"
                  : aboutOpen
                    ? "text-gray-900"
                    : translucent
                      ? "text-gray-700 hover:text-gray-900"
                      : "text-gray-500 hover:text-gray-900"
                  } group inline-flex items-center space-x-2 text-base leading-6 font-medium focus:outline-none transition ease-in-out duration-150`}
                onClick={() => setAboutOpen(!aboutOpen)}
              >
                <span>Client Stories</span>
                <svg
                  className={`${aboutOpen && (!isHome || !isCaseStudy)
                    ? "text-gray-600"
                    : translucent
                      ? "text-gray-700 hover:text-gray-900"
                      : isHome || isCaseStudy
                        ? "text-white"
                        : "text-gray-500 hover:text-gray-900"
                    }
    } h-5 w-5 transition ease-in-out duration-150`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              <Transition
                show={aboutOpen}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <div
                  className="absolute z-50 w-screen px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0"
                  style={{ maxWidth: "26rem" }}
                >
                  <OutsideClickHandler
                    onOutsideClick={(e) => {
                      const trigger = document.querySelector(
                        "[data-about-trigger]"
                      );
                      if (trigger !== e.target && !trigger.contains(e.target)) {
                        setAboutOpen(false);
                      }
                    }}
                  >
                    <div className="rounded-lg shadow-lg">
                      <div className="overflow-hidden rounded-lg shadow-xs">
                        <div className="px-5 py-5 space-y-5 bg-white sm:px-6 sm:py-6">
                          <div className="space-y-2">
                            <ul className="space-y-2">
                              <li className="flex text-base leading-6 justify-between gap-1">
                                <Link href="/case-studies/fantastic-fest">
                                  <a className="block font-normal text-gray-900 transition duration-150 ease-in-out hover:text-gray-800 hover:bg-gray-50 rounded-lg p-2">
                                    How Fantastic Fest designed a simpler and
                                    more creative experience
                                  </a>
                                </Link>
                              </li>
                              <li className="text-base leading-6 ">
                                <Link href="/case-studies/1946">
                                  <a className="block font-normal text-gray-900 transition duration-150 ease-in-out hover:text-gray-800 hover:bg-gray-50 rounded-lg p-2">
                                    How the documentary 1946: The Mistranslation
                                    that Shifted Culture used Eventive to
                                    maximize reach and impact
                                  </a>
                                </Link>
                              </li>
                              <li className="text-base leading-6 ">
                                <Link href="/case-studies/blackstar">
                                  <a className="block font-normal text-gray-900 transition duration-150 ease-in-out hover:text-gray-800 hover:bg-gray-50 rounded-lg p-2">
                                    How BlackStar expressed a genuine, unique
                                    personality
                                  </a>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="relative z-20 grid gap-6 px-5 py-3 bg-gray-50 sm:gap-8 sm:p-8">
                          <Link href="/case-studies">
                            <a className="flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-white">
                              <div className="space-y-1">
                                <p className="text-base font-medium leading-6 text-gray-900">
                                  More Client Stories
                                </p>
                              </div>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </OutsideClickHandler>
                </div>
              </Transition>
            </div>

            <div className="relative">
              <button
                data-more-trigger={true}
                type="button"
                className={`${isHome || isCaseStudy
                  ? "text-white"
                  : moreOpen
                    ? "text-gray-900"
                    : translucent
                      ? "text-gray-700 hover:text-gray-900"
                      : "text-gray-500 hover:text-gray-900"
                  } group inline-flex items-center space-x-2 text-base leading-6 font-medium  focus:outline-none transition ease-in-out duration-150`}
                onClick={() => setMoreOpen(!moreOpen)}
              >
                <span>
                  Pricing <span className="hidden xl:inline">& More</span>
                </span>
                <svg
                  className={`${isHome || isCaseStudy
                    ? "text-white"
                    : moreOpen
                      ? "text-gray-900"
                      : translucent
                        ? "text-gray-700 hover:text-gray-900"
                        : "text-gray-500"
                    }
                  } h-5 w-5 transition ease-in-out duration-150`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              <Transition
                show={moreOpen}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <div
                  className="absolute z-50 w-screen px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0"
                  style={{ maxWidth: "14.5rem" }}
                >
                  <OutsideClickHandler
                    onOutsideClick={(e) => {
                      const trigger = document.querySelector(
                        "[data-more-trigger]"
                      );
                      if (trigger !== e.target && !trigger.contains(e.target)) {
                        setMoreOpen(false);
                      }
                    }}
                  >
                    <div className="rounded-lg shadow-lg">
                      <div className="overflow-hidden rounded-lg shadow-xs">
                        <div className="relative z-20 grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                          <Link href="/pricing">
                            <a className="flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50">
                              <svg
                                className="flex-shrink-0 hidden w-6 h-6 text-red-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                />
                              </svg>
                              <div className="space-y-1">
                                <p className="text-base font-medium leading-6 text-gray-900">
                                  Pricing
                                </p>
                                {/* <p className="text-sm leading-5 text-gray-500">
                                  Learn about how we use MPAA-approved digital
                                  rights management technology and more to
                                  ensure maximum security for streaming content.
                                </p> */}
                              </div>
                            </a>
                          </Link>

                          <Link href="/antipiracy">
                            <a className="flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50">
                              <svg
                                className="flex-shrink-0 hidden w-6 h-6 text-red-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                />
                              </svg>
                              <div className="space-y-1">
                                <p className="text-base font-medium leading-6 text-gray-900">
                                  Antipiracy
                                </p>
                              </div>
                            </a>
                          </Link>

                          <Link href="/security">
                            <a className="flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50">
                              <svg
                                className="flex-shrink-0 hidden w-6 h-6 text-red-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                />
                              </svg>
                              <div className="space-y-1">
                                <p className="text-base font-medium leading-6 text-gray-900">
                                  Security
                                </p>
                                {/* <p className="text-sm leading-5 text-gray-500">
                                  Learn about how we use MPAA-approved digital
                                  rights management technology and more to
                                  ensure maximum security for streaming content.
                                </p> */}
                              </div>
                            </a>
                          </Link>

                          <a
                            href="https://help.eventive.org/"
                            target="_blank"
                            className="flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50"
                            rel="noreferrer"
                          >
                            <svg
                              className="flex-shrink-0 hidden w-6 h-6 text-red-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                              />
                            </svg>
                            <div className="space-y-1">
                              <p className="text-base font-medium leading-6 text-gray-900">
                                User Guide
                              </p>
                              {/* <p className="text-sm leading-5 text-gray-500">
                                Learn the ins and outs of the Eventive platform
                                with detailed tutorials, help videos, and more.
                              </p> */}
                            </div>
                          </a>

                          <Link href="/about">
                            <a className="flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50">
                              <svg
                                className="flex-shrink-0 hidden w-6 h-6 text-red-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                />
                              </svg>
                              <div className="space-y-1">
                                <p className="text-base font-medium leading-6 text-gray-900">
                                  The Eventive Story
                                </p>
                              </div>
                            </a>
                          </Link>
                        </div>
                        {/* <div className="px-5 py-5 space-y-5 bg-gray-50 sm:px-8 sm:py-8">
                          <div className="space-y-4">
                            <h3 className="text-sm font-medium leading-5 tracking-wide text-gray-500 uppercase">
                              Case studies
                            </h3>
                            <ul className="space-y-4">
                              <li className="text-base leading-6">
                                <a
                                  href="#"
                                  className="font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-700"
                                >
                                  Mountainfilm transforms into a virtual
                                  festival
                                </a>
                              </li>
                              <li className="text-base leading-6">
                                <a
                                  href="#"
                                  className="font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-700"
                                >
                                  Magnolia Pictures launches virtual theatrical
                                  distribution platform
                                </a>
                              </li>
                              <li className="text-base leading-6">
                                <a
                                  href="#"
                                  className="font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-700"
                                >
                                  Apple and A24 partner with AFI Docs for
                                  high-security US virtual theatrical release
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="text-sm leading-5">
                            <a
                              href="#"
                              className="font-medium text-red-600 transition duration-150 ease-in-out hover:text-red-500"
                            >
                              View all case studies &rarr;
                            </a>
                          </div>
                        </div>
                        */}
                      </div>
                    </div>
                  </OutsideClickHandler>
                </div>
              </Transition>
            </div>
          </nav>
          <div className="items-center justify-end hidden space-x-8 lg:flex lg:flex-1">
            <a
              href="https://admin.eventive.org/"
              target="_blank"
              className={`whitespace-no-wrap text-base leading-6 font-medium ${isHome || isCaseStudy
                ? "text-white"
                : translucent
                  ? "text-gray-700"
                  : "text-gray-500 hover:text-gray-900"
                }  focus:outline-none `}
              rel="noreferrer"
            >
              Organizer Login
            </a>
            <span className="inline-flex rounded-md shadow-sm">
              <Link href="/start">
                <a className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap transition duration-150 ease-in-out bg-red-600 border border-transparent rounded-md hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700">
                  Try Eventive
                </a>
              </Link>
            </span>
          </div>
        </div>
      </div>

      <Transition
        show={mobileOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute inset-x-0 top-0 z-50 p-2 transition origin-top-right transform lg:hidden">
          <div className="rounded-lg shadow-lg">
            <div className="bg-white divide-y-2 rounded-lg shadow-xs divide-gray-50">
              <div className="px-5 pt-5 pb-6 space-y-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="w-auto h-6"
                      src="https://eventive-co.imgix.net/images/eventive-logo.png?h=200"
                      alt="Eventive"
                    />
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                      onClick={() => setMobileOpen(!mobileOpen)}
                    >
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <nav className="grid row-gap-8">
                    <Link href="/film-festivals">
                      <a className="flex items-center p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50">
                        <svg
                          className="flex-shrink-0 hidden w-6 h-6 text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                          />
                        </svg>
                        <div className="text-base font-medium leading-6 text-gray-900">
                          Film Festivals
                        </div>
                      </a>
                    </Link>
                    <Link href="/filmmakers">
                      <a className="flex items-center p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50">
                        <svg
                          className="flex-shrink-0 hidden w-6 h-6 text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                          />
                        </svg>
                        <div className="text-base font-medium leading-6 text-gray-900">
                          Filmmakers
                        </div>
                      </a>
                    </Link>
                    <Link href="/cinema">
                      <a className="flex items-center p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50">
                        <svg
                          className="flex-shrink-0 hidden w-6 h-6 text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                          />
                        </svg>
                        <div className="text-base font-medium leading-6 text-gray-900">
                          Arthouse Cinema
                        </div>
                      </a>
                    </Link>
                    <Link href="/virtual">
                      <a className="flex items-center p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50">
                        <svg
                          className="flex-shrink-0 hidden w-6 h-6 text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                          />
                        </svg>
                        <div className="text-base font-medium leading-6 text-gray-900">
                          Eventive Virtual
                        </div>
                      </a>
                    </Link>
                  </nav>
                </div>
              </div>
              <div className="px-5 py-6 space-y-6">
                <div className="grid grid-cols-2 row-gap-4 col-gap-8">
                  <Link href="/case-studies">
                    <a className="text-base font-medium leading-6 text-gray-900 transition duration-150 ease-in-out hover:text-gray-700">
                      Client Stories
                    </a>
                  </Link>
                  <Link href="/about">
                    <a className="text-base font-medium leading-6 text-gray-900 transition duration-150 ease-in-out hover:text-gray-700">
                      Our Story
                    </a>
                  </Link>
                  <Link href="/pricing">
                    <a
                      href="#"
                      className="text-base font-medium leading-6 text-gray-900 transition duration-150 ease-in-out hover:text-gray-700"
                    >
                      Pricing
                    </a>
                  </Link>
                  <Link href="/antipiracy">
                    <a className="text-base font-medium leading-6 text-gray-900 transition duration-150 ease-in-out hover:text-gray-700">
                      Antipiracy
                    </a>
                  </Link>
                  <Link href="/security">
                    <a className="text-base font-medium leading-6 text-gray-900 transition duration-150 ease-in-out hover:text-gray-700">
                      Security
                    </a>
                  </Link>
                  <a
                    href="https://help.eventive.org"
                    target="_blank"
                    className="text-base font-medium leading-6 text-gray-900 transition duration-150 ease-in-out hover:text-gray-700"
                    rel="noreferrer"
                  >
                    User Guide
                  </a>
                </div>
                <div className="space-y-6">
                  <span className="flex w-full rounded-md shadow-sm">
                    <Link href="/start">
                      <a className="flex items-center justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-red-600 border border-transparent rounded-md hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700">
                        Try Eventive
                      </a>
                    </Link>
                  </span>
                  <p className="text-base font-medium leading-6 text-center text-gray-500">
                    Existing customer?
                    <a
                      href="https://admin.eventive.org"
                      target="_blank"
                      className="ml-2 text-red-600 transition duration-150 ease-in-out hover:text-red-500"
                      rel="noreferrer"
                    >
                      Organizer Login
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
