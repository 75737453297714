import React from "react";
import usePrefersReducedMotion from "../hooks/usePrefersReducedMotion";

const Canvas = ({ height = 800, width }) => {
  const canvas = React.useRef();
  const stripeColors = [
    "#7cc360",
    "#ffd400",
    "#0089ce",
    "#ed017f",
    "#8b3f8c",
    "#ef374d",
    "#f37321",
  ];
  const prefersReducedMotion = usePrefersReducedMotion();

  const draw = context => {
    function Stripe(x, y, width, height, color) {
      this.x = x;
      this.y = y;
      this.width = width;
      this.height = height;
      this.dy = Math.floor(Math.random() * (3 + 4) - 4);
      if (this.dy == 0) {
        this.dy = -1;
      }
      this.color =
        stripeColors[Math.floor(Math.random() * stripeColors.length)];

      this.draw = function () {
        context.beginPath();
        context.fillRect(this.x, this.y, this.width, this.height);
        context.fillStyle = this.color;
      };

      this.update = function () {
        if (this.y > innerHeight || this.y + this.height * 1.25 < 0) {
          if (!prefersReducedMotion) this.dy = -1 * this.dy;
        }
        if (!prefersReducedMotion) this.y += this.dy;
        if (!prefersReducedMotion) this.height += this.dy;
        this.draw();
      };
    }

    const numStripes = Math.floor(innerWidth / 50); // base number of stripes on the width of the canvas
    const stripes = [];
    for (var i = 0; i < numStripes; i++) {
      let stripe = new Stripe(
        Math.floor(Math.random() * innerWidth),
        Math.floor(Math.random() * height - height),
        Math.floor(Math.random() * (16 - 2) + 2),
        Math.floor(Math.random() * (1024 - 256) + 256)
      );
      stripes.push(stripe);
    }

    function animate() {
      requestAnimationFrame(animate);
      context.clearRect(0, 0, innerWidth, height);

      for (var i = 0; i < stripes.length; i++) {
        stripes[i].update();
      }
    }
    animate();
  };

  React.useEffect(() => {
    const context = canvas.current.getContext("2d");
    draw(context);
  }, []);

  return (
    <canvas
      width={width}
      height={height}
      className="absolute top-0 bottom-0 left-0 right-0 block bg-black -z-2"
      ref={canvas}
    />
  );
};

export default Canvas;
