import Head from "next/head";
import Nav from "./Nav";
import Footer from "./Footer";
import classNames from "classnames";

export default function Layout({
  title = null,
  short = false,
  children,
  mainClassName = null,
  noMargin = false,
  navTranslucent = false,
  isHome = false,
  isCaseStudy = false,
  noMarginTop = false,
}) {
  return (
    <>
      <Head>
        <title>
          {title
            ? `${title} | Eventive`
            : "Eventive | Smarter experiences for independent film"}
        </title>
      </Head>
      <Nav
        translucent={navTranslucent}
        isHome={isHome}
        isCaseStudy={isCaseStudy}
      />
      <main
        style={{
          minHeight: short ? "48vh" : "70vh",
          marginTop: noMarginTop && 0,
        }}
        className={classNames(
          noMargin && !noMarginTop
            ? "-mt-12"
            : "mt-10 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28",
          mainClassName
        )}
      >
        {children}
      </main>
      <Footer />
    </>
  );
}
